<template>
  <div>
    <div
      :style="getElementStyle"
      v-if="!loading && data.properties.filed_content != 'Hide'"
    >
      <el-row
        align="middle"
        v-if="
          !data.properties.hideLabel && data.properties.filed_content != 'Hide'
        "
      >
        <el-col
          :span="showLabel && !isDefalutPosq ? 4 : data.description ? 20 : 24"
          :style="computedStyles"
        >
          <span>
            <label
              for="text"
              v-if="hasLabel || !isView"
              :style="getLabelStyles"
              :class="{ 'highlight-border': highlight }"
            >
              {{ label || data.label }}
            </label>
          </span>
          <span
            v-if="data.validations.required && hasLabel"
            style="color: red; font-weight: bold"
          >
            *
          </span>
          <span style="margin-right: 5px">
            <el-popover
              v-if="data.description"
              placement="top-start"
              trigger="hover"
              :content="data.description"
            >
              <i class="el-icon-info" slot="reference"></i>
            </el-popover>
          </span>
        </el-col>
      </el-row>
      <el-row
        :span="showLabel && !isDefalutPos ? 24 : data.description ? 20 : 24"
      >
        <template v-if="data.inputType == 'CONCATENATE'">
          <el-input
            v-if="concatenate && !isView"
            :style="{ 'text-align': inputTextAlignment, ...getStyle }"
            :placeholder="data.placeholder || data.label"
            v-model="form[data.key]"
            :suffix-icon="data.properties.el_icon"
            @change="applyFormRules"
            :readonly="readonly()"
            :disabled="checkReadonly()"
          >
          </el-input>
        </template>
      </el-row>

      <el-row align="middle">
        <el-col :span="showLabel && !isDefalutPosq ? 12 : 24">
          <p v-if="hasError" style="color: red; font-size: 14px">
            {{ errorMsg }}
          </p>
          <p v-if="isView" :style="getStyle">
            <template v-if="form[data.key]">{{ form[data.key] }}</template>
            <template v-else>-</template>
          </p>
        </el-col>
      </el-row>
    </div>
  </div>
</template>
<script>
import userPermissionsHelper from "@/mixins/userPermissionsHelper";
import moment from "moment-timezone";
import { mapGetters } from "vuex";

export default {
  name: "templates-formComponentsExecute-StarRatingExecute",
  components: {},
  mixins: [userPermissionsHelper],
  props: [
    "data",
    "label",
    "form",
    "isView",
    "hasLabel",
    "isFromDocument",
    "fromRepeatable",
    "checkIsDisabled",
    "profilePage",
    "isEntityVariable",
    "originalField",
    "colorFields",
    "highlight",
    "fromEntityViews",
  ],
  mounted() {
    this.loading = true;
    if (this.data.styles && this.data.styles.labelStyle) {
      if (this.data.styles.labelStyle == "hide") {
        this.showLabel = false;
      }
    }
    if (!this.isEntityVariable) {
      this.concatenateData();
    }

    this.loading = false;
  },
  computed: {
    ...mapGetters("auth", ["getDefaultDateFormat"]),
    getStyle() {
      return this.data.styles &&
        this.data.styles.label_color &&
        !this.fromRepeatable
        ? `height:${this.data.height - 30}px; font-weight: 400; width: ${
            this.data.width
          }px;`
        : "height: 70px";
    },
      computedStyles() {
      const styles = {};
      styles.display = "flex";
      if (this.data?.styles?.labelAlignments) {
        styles.justifyContent = this.data.styles.labelAlignments;
      }
      return styles;
    },
    getLabelStyles() {
      return this.data.styles && this.data.styles.label_color
        ? `color: ${this.data.styles.label_color}; `
        : "font-weight: 700";
    },
    isDefalutPosq() {
      if (this.data.styles && this.data.styles.labelStyle == "right") {
        return false;
      } else {
        return true;
      }
    },
    inputTextAlignment() {
      return this.textAlignment ? this.textAlignment : "left";
    },
  },
  data() {
    return {
      isList: false,
      hasError: false,
      errorMsg: "",
      showLabel: true,
      isDefalutPos: true,
      loading: false,
      inputValue: "",
      maskedValue: "",
      inputValue2: "",
      concatenate: true,
      previousFormData: {},
      textAlignment: "left",
    };
  },

  methods: {
    applyFormRules() {
      this.$emit("applyFormRules");
    },
    readonly() {
      //If it's an entity varaible, have to check access
      if (
        this.data.field_assignable === "read_only" &&
        this.data.is_entity_variable
      ) {
        return true;
      }
      if (
        this.data &&
        this.data.properties &&
        this.data.properties.filed_content === "READ ONLY"
      ) {
        return true;
      }
    },
    checkReadonly() {
      if (this.checkIsDisabled) {
        return true;
      }
      if (this.fieldFilledByCurrentUser(this.data.filled_by) === "false") {
        if (this.data.styles && this.data.styles.labelStyle) {
          if (this.data.styles.labelStyle == "hide") {
            this.showLabel = false;
          }
          // if (this.data.styles.labelStyle == "right") {
          //   this.isDefalutPos = false;
          // }
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      } else {
        if (
          this.data &&
          this.data.properties &&
          this.data.properties.filed_content === "DISABLED"
        ) {
          return true;
        }
        return !this.fieldFilledByCurrentUser(this.data.filled_by);
      }
    },
    removeSpecailCharacters(value) {
      if (this.data?.remove_special_characters) {
        return value.replace(/[^a-zA-Z0-9]/g, "");
      } else {
        return value;
      }
    },
    async concatenateData() {
      this.concatenate = false;
      let requiredValue = "";
      await this.data.selected_fields.forEach((e) => {
        if (
          this.form[e.key] &&
          !["", null, undefined].includes(this.form[e.key])
        ) {
          let seperator =
            this.data.selected_fields.indexOf(e) ==
            this.data.selected_fields.length - 1
              ? ""
              : this.data.separator
              ? this.data.separator
              : "";
          let value = this.form[e.key];
          let timeZone = Intl.DateTimeFormat().resolvedOptions().timeZone;
          const format = this.getDefaultDateFormat
            ? this.getDefaultDateFormat
            : "MM-DD-YYYY";
          switch (e.inputType) {
            case "ENTITY":
              if (e.allow_multiple && !this.form[e.key].length) {
                value = "";
              } else {
                value = this.form[`${e.key}/name`];
              }
              break;
            case "DATE":
              value = moment(this.form[e.key])
                .tz(timeZone.toString())
                .format(format);
              break;
            case "DATE_TIME":
              value = moment(this.form[e.key])
                .tz(timeZone.toString())
                .format(`${format} HH:mm:ss`);
              break;
            case "DATE_RANGE":
              break;
          }
          requiredValue =
            requiredValue +
            this.removeSpecailCharacters(value) +
            (value && this.data.show_labels ? ` ${e.label}` : "") +
            seperator;
        }
      });
      // this.form[this.data.key] = requiredValue;
      if(requiredValue !== this.form[this.data.key]){
        this.$set(this.form, this.data.key, requiredValue);
        this.applyFormRules();
      }
      setTimeout(() => {
        this.concatenate = true;
      }, 10);
    },
  },
  watch: {
    "data.styles.inputTextAlignments": {
      handler(newVal) {
        // Whenever inputTextAlignments changes, update the computed property.
        this.textAlignment = newVal;
      },
      immediate: true, // This will trigger the handler immediately when the component is created
    },
    form: {
      async handler() {
        if (
          !this.isEntityVariable &&
          this.data.selected_fields &&
          this.data.selected_fields.length
        ) {
          let currentFormData = {};
          this.data.selected_fields.forEach((e) => {
            this.$set(currentFormData, e.key, this.form[e.key]);
            if (this.form[e.key + "/name"]) {
              this.$set(
                currentFormData,
                e.key + "/name",
                this.form[e.key + "/name"]
              );
            }
          });
          if (
            JSON.stringify(this.previousFormData) !==
            JSON.stringify(currentFormData)
          ) {
            this.concatenateData();
            this.data.selected_fields.forEach((e) => {
              this.$set(this.previousFormData, e.key, this.form[e.key]);
              if (this.form[e.key + "/name"]) {
                this.$set(
                  this.previousFormData,
                  e.key + "/name",
                  this.form[e.key + "/name"]
                );
              }
            });
          }
        }
      },
      deep: true,
    },
  },
};
</script>

<style lang="scss" scoped>
.highlight-border {
  color: red;
}
</style>
